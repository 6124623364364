// **********vuex状态管理**********
import Vue from 'vue'
import vuex from 'vuex'

Vue.use(vuex)
let store = new vuex.Store({
    state: {
        token: '',
        is_finish: false
    }
})
export default store
