import Vue from 'vue'
import App from './App'
import router from './router'
import store from './libs/store'
import GLOBAL from './config/config'
import ElementUI from 'element-ui'
import './assets/css/basic.less'
import htmlToPdf from './utils/index'
import 'element-ui/lib/theme-chalk/index.css'
import Print from './libs/print'

// import './libs/validate'
import {get, post} from './libs/axios'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(htmlToPdf)
// vue-layer
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'

Vue.prototype.$layer = layer(Vue);
// axios
Vue.prototype.$get = get
Vue.prototype.$post = post
// GLOBAL
Vue.prototype.GLOBAL = GLOBAL
// Print
Vue.use(Print)
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
