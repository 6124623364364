import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Routers = [
    {
        path: '/',
        meta: {
            title: '首页'
        },
        component: (resolve) => require(['@/page/index'], resolve)
    },
    {
        path: '*',
        meta: {
            title: '首页'
        },
        component: (resolve) => require(['@/page/index'], resolve)
    },
    {
        path: '/index',
        meta: {
            title: '首页'
        },
        component: (resolve) => require(['@/page/index'], resolve)
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: (resolve) => require(['@/page/login'], resolve)
    },
    {
        path: '/register',
        meta: {
            title: '注册'
        },
        component: (resolve) => require(['@/page/register'], resolve)
    },
    {
        path: '/testcenter',
        meta: {
            title: '测评中心',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/testcenter'], resolve)
    },
    {
        path: '/baseinfo',
        meta: {
            title: '基本资料',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/baseinfo'], resolve)
    },
    {
        path: '/test',
        meta: {
            title: '测试中',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/test'], resolve)
    },
    {
        path: '/about_us',
        meta: {
            title: '关于我们'
        },
        component: (resolve) => require(['@/page/about_us'], resolve)
    },
    {
        path: '/news',
        meta: {
            title: '资讯信息'
        },
        component: (resolve) => require(['@/page/news'], resolve)
    },
    {
        path: '/newsinfo',
        meta: {
            title: '资讯信息'
        },
        component: (resolve) => require(['@/page/newsinfo'], resolve)
    },
    {
        path: '/user',
        meta: {
            title: '用户中心',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/user'], resolve)
    },
    {
        path: '/inventoryList',
        meta: {
            title: '量表列表',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/inventoryList'], resolve)
    },
    {
        path: '/history',
        meta: {
            title: '测评记录',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/history'], resolve)
    },
    {
        path: '/form',
        meta: {
            title: '我的量表',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/form'], resolve)
    },
    {
        path: '/edit',
        meta: {
            title: '资料修改',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/edit'], resolve)
    },
    {
        path: '/case',
        meta: {
            title: '案例轨迹',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/case'], resolve)
    },
    {
        path: '/feedback',
        meta: {
            title: '意见反馈',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/feedback'], resolve)
    },
    {
        path: '/setting',
        meta: {
            title: '设置',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/password'], resolve)
    },
    {
        path: '/language',
        meta: {
            title: '沟通及语言结果',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/result/language'], resolve)
    },
    {
        path: '/writing',
        meta: {
            title: '写字表现结果',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/result/writing'], resolve)
    },
    {
        path: '/sense',
        meta: {
            title: '感觉统合结果',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/result/sense'], resolve)
    },
    {
        path: '/autism',
        meta: {
            title: '自闭症类群障碍结果',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/result/autism'], resolve)
    },
    {
        path: '/sift',
        meta: {
            title: '发展筛查结果',
            requireAuth: false
        },
        component: (resolve) => require(['@/page/result/sift'], resolve)
    },
    {
        path: '/apply',
        meta: {
            title: '申请资质',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/apply'], resolve)
    },
    {
        path: '/applyList',
        meta: {
            title: '申请列表',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/applyList'], resolve)
    },
    {
        path: '/words',
        meta: {
            title: '词汇',
            requireAuth: true
        },
        component: (resolve) => require(['@/page/words'], resolve)
    },
]

const RouterConfig = {
    // mode: 'history',
    mode: 'hash',
    routes: Routers
}
const router = new VueRouter(RouterConfig)
router.beforeEach((to, from, next) => {
    next()
    // 判断该路由是否需要登录权限
    if (to.matched.some(record => record.meta.requireAuth)) {
        // 判断当前的token是否存在 ，登录存入的token
        if (localStorage.getItem('token')) {
            next()
        } else {
            next({
                path: '/login',
                // 将跳转的路由path作为参数，登录成功后跳转到该路由
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next()
    }
})
router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router
