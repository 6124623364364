// const WEB_SITE = 'http://xiantest.3todo.com' // 测试服
// const WEB_SITE = 'http://xianxinli.3todo.com' // 正式服
const WEB_SITE = 'https://www.psyxa.com' // 正式服
const WEB_NAME = '喜安人文心理'
const qnDomail = 'http://xaqn.3todo.com/'

export default {
    WEB_SITE,
    WEB_NAME,
    qnDomail
}