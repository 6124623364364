// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default {
    install(Vue, options) {
        Vue.prototype.getPdf = function (title, fn) {
            let page = true // 是否分页
            let cntElem = document.querySelector('#pdfDom')
            let width = cntElem.offsetWidth // 获取dom 宽度
            let height = cntElem.offsetHeight // 获取dom 高度
            let canvas = document.createElement('canvas') // 创建一个canvas节点
            let scale = 2 // 定义任意放大倍数 支持小数
            canvas.width = width * scale // 定义canvas 宽度 * 缩放
            canvas.height = height * scale // 定义canvas高度 *缩放
            canvas.getContext('2d').scale(scale, scale) // 获取context, 设置scale
            let opts = {
                scale: 1, // 添加的scale 参数
                canvas: canvas, // 自定义 canvas
                // logging: true, // 日志开关，便于查看html2canvas的内部执行流程
                width: width, // dom 原始宽度
                height: height, // dom 原始高度
                useCORS: true, // 【重要】开启跨域配置
                allowTaint: true
            }
            html2Canvas(cntElem, opts).then(function (canvas) {
                let ctx = canvas.getContext('2d')
                // 关闭抗锯齿
                ctx.mozImageSmoothingEnabled = false
                ctx.webkitImageSmoothingEnabled = false
                ctx.msImageSmoothingEnabled = false
                ctx.imageSmoothingEnabled = false
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (page) {
                    let pageHeight = contentWidth / 592.28 * 841.89
                    let leftHeight = contentHeight
                    let position = 0
                    let imgWidth = 595.28
                    let imgHeight = 592.28 / contentWidth * contentHeight
                    if (leftHeight < pageHeight) {
                        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                    } else {
                        while (leftHeight > 0) {
                            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight
                            position -= 841.89
                            if (leftHeight > 0) {
                                PDF.addPage()
                            }
                        }
                    }
                } else {
                    let imgWidth = 595.28
                    let imgHeight = 592.28 * contentHeight / contentWidth
                    console.log(imgHeight)
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                }
                PDF.save(title + '.pdf')
                fn()
            })
        }
    }
}
